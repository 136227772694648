import React from 'react';
import choose1 from "../../../assets/images/resource/choose-01.png";
import choose2 from "../../../assets/images/resource/choose-02.png";
import choose3 from "../../../assets/images/resource/choose-02.png";
import BannerBg1 from "../../../assets/images/shapes/shape-061.png";
import Bannerback from "../../../assets/images/resource/base.png";
import choose4 from "../../../assets/images/resource/choose-02.png";
import dai1 from "../../../assets/images/Wegood.JPG";
import dai2 from "../../../assets/images/idea.jpg";
import CountUp from 'react-countup';
const Choose = () => {
    return (
        <section className="choose__us p_relative">
            <div className="choose__us__data">
                <div className="auto-container">
                <div className="title__data">
                                <div className="sub__title">
                                        <h4>Why choose us</h4>
                                    </div>
                                    <div className="title">
                                        <h2>We Provide You The Best Work </h2>
                                    </div>
                                </div>                   
                    <div className="row" >
                        <div className="col-lg-6 col-md-12" >
                            <div className="about__block p_relative" style={{ backgroundImage: `url(${Bannerback})` }}>
                                <img src={dai1} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="choose__block__right">
                                
                            </div>    
                                
                            
                            <div className="about__block p_relative" style={{ backgroundImage: `url(${Bannerback})` }}>
                                <img src={dai2} />
                            </div>
                        
                                {/* <div className="team__data three">
                                    <div className="team__data__left">
                                        <img src={choose4} alt="" />
                                    </div>
                                    <div className="team__data__right">
                                        <h3>Expert Team :</h3>
                                        <p>Our dedicated team of technology professionals comprises experienced engineers, developers, and researchers who are passionate about pushing the boundaries of technology.</p>
                                    </div>
                                </div> */}
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Choose;