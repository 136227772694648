import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/service-details.jpg";
import basebg from "../../assets/images/resource/base.png";


const DigitalMarketing = () => {
      pageTitle('Content Marketing');
      
      const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
      });
    
    
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);
    
        // Clear the form fields after submission if needed
        setFormData({
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: ''
        });
      };

      const servicesData = [
        { id: 1, title: 'Patent Litigation Support', slug: '/PatentLitigationSupport' },
        { id: 2, title: 'Intellectual Prop. Commercialization', slug: '/IntellectualProp.Commercialization' },
        { id: 3, title: 'Patent Prosecution Support', slug: '/PatentProsecutionSupport' },
        { id: 4, title: 'MarketResearch', slug: '/MarketResearch' },
      ];
    
      const renderServices = () => {
        return servicesData.map((service) => (
          <li key={service.id} className={service.slug === '/content-marketing' ? 'active' : ''}>
            <Link to={service.slug}>{service.title}</Link>
          </li>
        ));
      };
     
  return (
    
    <>
        <HeaderOne></HeaderOne>
        <BreadCrumb></BreadCrumb>
        <div className="service__details p_relative ">
            <div className="auto-container">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="service__details__left">
                            <div className="sidebar__widget">
                                <div className="all__service">
                                    <h4>All Services</h4>
                                </div>
                                <ul className="service__details__1__cat">
                                    {renderServices()}
                                </ul>
                            </div>
                            <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>                            
                                <h3>Contact Us For Any Require</h3>
                                <div className="services__icon">
                                    <i className="icon-19"></i>
                                </div>
                                <p>Need help?  Call us:</p>
                                <h4>(+800) 1234 5678 90</h4>
                            </div> 
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-12 col-md-12">
                        <div className="service__right">
                            {/* <figure>
                                <img src={service} alt="" />
                            </figure> */}
                            <h2><span>Patent Litigation </span> Support.</h2>
                            <p><b>•	Claim Charts</b></p>
                            <p><b>•	Validity Search</b></p>
                            <p><b>•	Invalidity Search</b></p>
                            <p><b>•	Inter-Party Review</b></p>
                            <p><b>•	Pre/Post Grant Opposition</b></p>
                            <p> </p>
                            <h2>Why is <span>Patent Litigation Support</span> Needed ?</h2>

                            <p>A patent is a legal right conferred upon the applicant/owner of the patent by the government of a particular jurisdiction for their invention. Given the legal nature of patents, various legal proceedings are associated with them from the moment of filing until the expiration of the patent.
                           </p><br></br><p> Moreover, specific timelines are linked to each legal event. Therefore, staying informed about all legal developments related to both competitors' patents and your own is crucial. Leveraging the legal landscape to gain a competitive advantage can help in staying ahead of the competition.</p>
                           <br></br><p> Over time, the significance of technology in the business landscape has multiplied. Consequently, companies actively involved in developing new technologies often pursue patents to protect their inventions. The surge in patent applications has, in turn, heightened the opportunities for patent licensing and patent lawsuits cases.</p>
                           <br></br><p> Companies/organizations who are facing infringement lawsuits or aiming to prevent them often opt for patent invalidity searches to potentially invalidate their competitors' patent claims and mitigate the risk of punitive damages. Additionally, the preparation of Claim Charts ensures accurate mapping of all patent claims.
                            </p>   
                            <h2>Support for <span>Patent Litigation</span></h2>
                            <p>
                                Patent litigation is a legal process where one party takes legal action (sues) against another for using a patent without the appropriate license or permission. To pursue financial compensation and secure a restraining order against the violation, the patent applicant/owner may initiate a lawsuit in a federal district court against a business or individual. </p>
                                <br></br> <p> Under 35 USC 286, the applicant/owner has a 6-year window from the date of infringement to initiate a lawsuit. In patent litigation, the validity and structure of the patent's claims are the sole aspects under contention. If either of these is found to be inaccurate, it may lead to a determination of non-infringement, patent revocation, and ultimately, a loss in litigation.
                            </p>
                            <h2>What is the Significance of <span>Patent Litigation</span > ?</h2>
                            <br></br> <p>Engaging in patent litigation is essential to safeguard your patent rights and prevent infringement. Possessing a patent does not guarantee immunity from infringement, and if you suspect that someone has utilized your invention without authorization, you have the option to take legal action through a patent lawsuit.</p> 
                            <br></br>  <p>  Patent litigation, however, consumes both time and financial resources. Typically, large corporations serve as defendants, with small businesses acting as plaintiffs. Consequently, the plaintiff faces a challenging legal condition.
                            </p>
                            <h2><span>What makes Matic Research the preferred</span>  choice for your “Patent Litigation..?</h2>
                            <p>
                                <b>Matic Research</b> offers years of experience to defend our clients' rights in legal proceedings, regardless of whether they are the Plaintiffs or Defendants. Our litigation team is made up of knowledgeable intellectual property specialists with a wealth of expertise in supporting patent litigation and effectively handling challenging cases on behalf of our customers. Our experts work directly with customers to assess a case's complexities and provide the best guidance possible.
                            </p>
                            <h2><span>Claim </span >Chart</h2>
                            <br></br> <p>Patent claim chart serves as a visual or structured view/representation, either in graphic or tabular form, presenting the analyzed data within a patent claim. Essentially, it encapsulates all the information scrutinized within a patent claim. These charts are valuable tools aiding in patent validation analysis and the determination of potential infringement.</p>
                            <br></br><p> Understanding the interpretation of each component of the claim is crucial, emphasizing comprehension beyond mere synonyms or comprehensive keywords. Various types of claim charts exist, including Initial or preliminary infringement contentions (PICs), domestic industry (DI) charts utilized in ITC importation actions, expert claim charts, those presented during licensing negotiations, and design patent charts.</p>
                            <br></br><p> These charts serve diverse purposes, including the PTO's reexamination during prosecution stages or facilitating Patent Trial and Appeal Board (PTAB) assessments regarding a patent's validity.
                            </p>
                            <h2><span>What is the Patent </span >Claim chart purpose ?</h2>
                            <br></br><p>The primary aim of a Patent claim chart is to meticulously break down each aspect into its constituent elements or constraints. Through the application of this chart, the evidence typically demonstrates or examines how the claimed invention is utilized in products, services, or standards. The initial step in crafting a claim chart involves dissecting a claim and identifying its various limiting components.</p> 
                            <br></br><p> In numerous instances of patent assessment, including those related to patent invalidity analysis or litigation, patent infringement assessment, patent licensing, and matters concerning claim interpretation, a claim chart is devised.</p> 
                            <br></br> <p>The purpose of evaluating invalidity in litigation scenarios, Claim charts play an important  role in affirming or disproving the uniqueness of a claim concerning existing prior art. Conversely, in the investigation and litigation of infringement cases, Claim charts assist in confirming or challenging whether every constraint/element of the claim is encompassed within a product, service, or standard.
                            </p>
                            <h2><span>Type of </span >Claim Charts ?</h2>
                            <p>The patent holder/owner/Plaintiff develops an Evidence of Use (EOU) or Infringement Claim Chart to illustrate how a product or method is alleged to infringe, delineating each claim component to satisfy the "all elements or component test/rule" for infringement.</p> 
                            <br></br> <p>  Invalidity Chart, referred to as a "Chart of References," illustrates the patent's invalidity due to anticipation or obviousness. This document is prepared by a defendant or a party accused of patent infringement.   </p> 
                            <br></br> <p>   Both Holder/owner/Plaintiff and defendants create a Claim Construction or Claim Interpretation Chart, presenting patent specifications or citations from technical literature with precise interpretations of claim language.
                            </p>
                            <h2><span>Why you should choose Matic Research </span >to prepare your Patent Claim Chart ?</h2>
                            <p>	We aid patent holder/owner/Plaintiff and their legal representatives by crafting various types of claim charts associated with Invalidations, Infringements, Prior Arts, and more. </p>
                            <br></br><p>	Tailored Reports: We offer solutions and adjustments aligned with customer needs and specific target areas. </p>
                            <br></br><p>	Delivering outputs at a highly competitive cost structure and with rapid turnaround times. </p>
                            <br></br><p>	Employing a team of top-notch subject matter experts who ensure thorough and efficient detailed reporting.
                            </p>
                            <h2><span>Validation </span >Search</h2>
                            <p>The <b>Patent Validation </b>Search is conducted by the assignee/owner of the patent, aims to instill confidence in the validity and enforceability of their granted patent. This comprehensive search covers both Patent and Non-Patent Literature, focusing on distinguishing the invention from existing Prior Art and demonstrating its novelty in the marketplace. Its primary goal is to highlight strengths and validate the granted patent's authenticity. Such searches are pivotal during IP Due Diligence processes.</p>
                            <br></br> <p><b>Organizations involved </b>in mergers or acquisitions, especially when patents are a significant part of the company's assets, undertake Patent Validity searches to reaffirm patent validity and prevent future invalidation risks. These searches can occur before or after a patent is granted. Even individuals who have recently filed a patent application may opt for a validity search to assess its strength, ensuring the invention's novelty and safeguarding against potential invalidation issues in the future.</p>
                            <h2><span>How we can help </span >you in your Patent Validation Search ?</h2>
                            <p><b>Our specialized team </b>kis equipped to manage diverse Patent validations, accessing extensive databases to assess validity and explore most relevant prior art. Our experts possess technical domain proficiency and understand CPC 52, 53 & 54, as well as USC 101, 102, and 103.</p>
                            <br></br> <p>Furthermore, we maintain networks with local agents and patent translation experts adept at handling Validation and Invalidation procedures across all contracting states.</p>
                            <h2><span>Why you should choose </span >Matic Research for Patent Validation Search ?</h2>
                            <p>	Validation Searches demand a thorough examination of all patent and non-patent literature released before the priority date of the specified patent.</p>
                            <br></br><p>	 Matic Research validation search reports offer in-depth analyses of claim and their enablement, a summary of the search findings, a matrix outlining of the identified prior art references, and a detailed assessment of pertinent classifications. </p>
                            <br></br><p>	We conduct classification searches using various systems such as CPC Classifications, US Classifications, and IPC Classifications. </p>
                            <br></br><p>	Proficiency in utilizing diverse patent and non-patent databases. </p>
                            <br></br><p>	Scrutiny of the file wrapper or prosecution history associated with the target/subject patent. </p>
                            <br></br><p>	Charting the claims of the target/subject patent alongside relevant references outlined in the search report.</p>
                            <br></br><p>Identifying and providing commentary on sections explaining the relevance of references in the search report. </p>
                            <br></br><p>	Matic Research validation search reports are comprehensive and self-explanatory, comprising detailed analyses of prior art, web links for complete specifications, visual representations, analyses of relevant references, complete bibliographic data, and their legal status. </p>
                            <br></br><p>	Inclusion of copies of patent and non-patent references mentioned in the patent examiner search report.</p>
                            <br></br><p>	Multiple quality assurance conducted before delivering the final search report. </p>
                            <p>	Delivering exceptional quality at a reasonable cost. </p>
                            <h2><span>Invalidation </span >Search</h2>
                            <p>A <b>Patent invalidity </b>search is a focused exploration for previously published information, including patents, non-patent materials, and other accessible data, predating a patent's filing or priority date. Its aim is to reveal evidence that questions the uniqueness and inventive nature of the patented idea. Primarily, this search serves two purposes: to confirm the legitimacy of a patent's enforceability (validity check) or to challenge its assertions (invalidity check). Additionally, it's frequently conducted to assess a patent's robustness against validity challenges.</p>
                            <br></br><p>Invalidation efforts can take place in various realms of intellectual property, like Patent Trial and Appeal Board (PTAB) proceedings, Inter Partes Review (IPR), cases within the International Trade Commission (ITC), Covered Business Method (CBM) reviews, and Post-Grant Review (PGR) processes.</p>
                            <h2><span> Objectives of Patent invalidity </span >search ?</h2>
                            <p>The main aim of conducting a Patent Invalidity or Invalidation search is to discover any significant prior information that the Patent examiner/any other researcher might have overlooked, potentially proving the patent's invalidity and leading to its nullification.</p>
                            <h2><span> Reasons for conducting  </span >Invalidation search ?</h2>
                            <p>There various key reasons behind conducting a patent invalidity search:</p>
                            <p>	To counter claims of patent infringement</p>
                            <p>	To ascertain the legitimacy of patent claims by others</p>
                            <p>	To circumvent steep licensing costs</p>
                            <p>Additionally, an invalidity or patent invalidation search can be seen as an extension of the prior art search. It delves deeper into language, comprehensive concepts, and ideas, expanding beyond a typical prior art exploration.</p>
                            <h2><span> Patent Invalidation </span >Search profits ?</h2>
                            <p>A Patent Invalidation search aids in discovering relevant historical materials that can dispute the legitimacy of a patent. It serves as a defensive measure when a client fears potential infringement of a particular patent, especially when facing a competitor's infringement lawsuit.</p>
                            <br></br> <p>The patent invalidation search aims to restrict the boundaries of the patent under review and could potentially lead to nullifying a previously approved patent. Conducting this search supports clients in defending their case against claims of infringement, which save millions of dollar of the client and in licensing matters.</p>
                            <h2><span> Why you should choose  </span >Matic Research ?</h2>
                            <p>	Utilization of diverse search approaches to contribute to an effective strategy for identifying pertinent prior art. </p>
                            <br></br><p>	Proficient use of multiple patent and non-patent databases with efficiency. </p>
                            <p>The effective search strategy comprises:</p>
                            <p>	<b>Extracting insights from the file wrapper: </b>Assessing cited references in the patent's file wrapper (along with related ones), verifying their relevance, exploring reasons for their inability to invalidate recent claims, and extracting insights for the initial step.</p>
                            <br></br><p>	<b>Thorough exploration of patent classes: </b>Delving beyond just the patent's classes, diving deep into the domain to pinpoint the most specific class targeting the features. This involves examining cited results, their related citations, and relevant sources like free and paid databases Patents.</p>
                            <br></br><p>	<b>Comprehensive perspective analysis: </b>Prior to commencing the search, brainstorming with the expert team to explore various potential interpretations and directions concerning the key features. </p>
                            <br></br><p>	Identification of segments within prior art that elucidate the relevancy of a reference. </p>
                            <br></br><p>	A comprehensive and self-explanatory invalidation search report including a thorough prior art analysis, web links for detailed specification review, visual aids, legal status and their complete bibliographic data of the references. </p>
                            <h2><span> Pre and Post </span >Grant Opposition</h2>
                            <p>The patent application process doesn't conclude upon patent approval. As a patent holder/owner, continual vigilance over competitors and emerging market entrants is essential to identify similar intellectual property assets that others may seek to patent. You possess the right, within a specified timeframe, to contest an applicant's submission as the owner of the intellectual property. This necessitates utilizing a patent watch, enabling ongoing monitoring of industry technology trends and competitors' commercial activities. </p>
                            <br></br><p>At Matic Research we offer cost-effective Patent Opposition Services.
                            Our clients have greatly benefitted from our proactive monitoring of rival patents and our proficient filing of Pre- and Post-grant oppositions, backed by comprehensive searches. Our legal experts and analysts possess extensive knowledge in both Pre-grant and Post-grant oppositions, coupled with exceptional search capabilities.
                            </p>
                            <p>Matic Research Patent Opposition service is designed to strategically safeguard your patent's exclusivity against competitors seeking to encroach upon your intellectual property, whether the opposition's patent is in the Pre-grant or Post-grant phase.</p>
                            <h2><span> Pre Grant  </span >& Post Grant Opposition ?</h2>
                            <p>Challenging a patent grant is referred to as Patent opposition, allowing a third party to dispute a patent's validity. Depending on the patent's stage, opposition can occur as either Pre-grant or Post-grant. Pre-grant oppositions are initiated following the publication of a patent application, while Post-grant oppositions are lodged within 12 months of a patent's grant.</p>
                            <p>Pre-grant oppositions can be initiated by anyone, whereas Post-grant proceedings can only be initiated by someone engaged and interested in a similar field as the innovation. Pre-grant opposition tends to be more cost-effective and leads to swifter resolution of cases on merits, thereby reducing expenses associated with contesting post-grant proceedings.</p>
                            <h2><span> Why you Opt </span >Matic Research ?</h2>
                            <p>	An adept team comprising diverse professionals well-versed in Indian and global patent laws.</p>
                            <p>	Comprehensive understanding of patent offices across different jurisdictions and their operational methodologies. </p>
                            <p>	Swift and efficient delivery timelines.</p>
                            <p>	For best quality of result.</p>
                            <h2><span> Inter Partes </span >Review Service</h2>
                            <p>Inter Partes Review (IPR) is a legal process available in the United States Patent and Trademark Office (USPTO) that allows third parties to challenge the validity of one or more claims in a granted patent. It is a type of post-grant review proceeding established by the America Invents Act (AIA) in 2012.</p>
                            <br></br><p>Anyone who is not the patent owner may submit a petition for a patent review. Whether or not to have a trial will be determined by the Patent Trial and Appeal Board (PTAB). It will take around half a year from the date the petition is submitted. With a few exceptions, the case will be finished within a year if a trial is scheduled.</p>
                            <br></br><p>The reasons for filing an IPR (Inter Partes Review) are more restricted than those for filing a PGR; a petitioner may only request the cancellation of one or more patent claims based on prior art that includes a collection of patents or printed publications, and only on a basis that might be brought under USC 102 or 103.</p>
                            <h2><span> What makes Matic Research </span >the preferred choice for your "Patent Litigation" ?</h2>
                            <p>	Our experienced team is handling clients’ (Inter Partes Reviews) IPRs and is knowledgeable about the quick-paced processes as well as the legal and technological nuances involved.</p>
                            <br></br><p>	Our IP specialists are highly adept at addressing a variety of patent validity issues in Inter Partes Review (IPR) proceedings before the Patent Trial and Appeal Board (PTAB).</p>
                            <br></br><p>	We consistently provide counsel to clients on both sides of legal disputes, whether they are challenging the validity of a patent or defending against such challenges.</p>

                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="#"><i className="icon-04"></i> Patent Litigation Support</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="#"><i className="icon-04"></i> Intellectual Prop. Commercialization</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="#"><i className="icon-04"></i> Patent Prosecution Support </Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="#"><i className="icon-04"></i> MarketResearch</Link></li>
                                </div>
                            </div>
                            <p>
                                The applications of AR are vast and diverse. From entertainment and gaming, where users can see virtual characters in their surroundings, to education, where complex subjects can be visualized and explained in an immersive way,. We understand that every business has unique needs. Our technology solutions are tailored to match your specific requirements, ensuring optimal performance and efficiency. 
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="service__contact see__pad p_relative">
            <div className="auto-container">
                <div className="service__contact__block">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="service__form__data">
                                <div className="form__title">
                                    <div className="title__data">
                                        <div className="sub__title">
                                            <h4>Contact Us</h4>
                                        </div>
                                        <div className="title">
                                            <h2>Send message</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-inner">
                                    <form onSubmit={handleSubmit} method="post" action="contact.php" className="default-form"> 
                                        <div className="row clearfix">
                                            <div className="col-xl-6 form-group">
                                                <input type="name" name="name" placeholder="Your Name *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="email" name="email" placeholder="Your Email *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="subject" placeholder="Your Subject " required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="phone" placeholder="Your Phone " required="" />
                                            </div>
                                            <div className="ccol-xl-6 col-lg-12 form-group">
                                                <textarea name="message" placeholder="Message"></textarea>
                                            </div>
                                            <div className="col-xl-12 form-group message-btn btn-box">
                                                <button className="theme-btn theme-btn-one" type="submit" name="submit-form">Send your Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 p_relative">
                            <div className="service__contact__img">
                                <figure>
                                    <img src={two} alt=" /" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterOne></FooterOne>
    </>
  );
};

export default DigitalMarketing;
