import React from 'react';
import { Link } from 'react-router-dom';


const Service = () => {
    const servicesData = [
      {
        id: 1,
        title: 'Patent Prosecution Support',
        description: 'Comprehensive patent prosecution support services for navigating the complexities of patent application processes.',
        icon: 'icon-04',
        href: '/services-details',
        data_aos_delay:'00ms',
      },
      {
        id: 2,
        title: 'Intellectual Prop. Commercialization',
        description: 'Our Intellectual property commercialization service helps you maximize the value of your inventions by facilitating licensing, partnerships, and commercialization strategies.',
        icon: 'icon-08',
        href: '/services-details',
        data_aos_delay:'200ms',
      },
      {
        id: 3,
        title: 'Patent Litigation Support',
        description: 'Our patent litigation support service offers specialized legal aid and expert guidance to navigate disputes concerning intellectual property rights.',
        icon: 'icon-12',
        href: '/services-details',
        data_aos_delay:'400ms',
      },
      {
        id: 4,
        title: 'Market Research',
        description: 'We analyze industry trends, competitor activity, and consumer preferences to guide strategic decisions for optimal intellectual property protection and commercialization.',
        icon: 'icon-12',
        href: '/services-details',
        data_aos_delay:'400ms',
      },
      {
        id: 5,
        title: 'R&D Support',
        description: 'We Research and Development Support for Data Collection and Analysis,Prototype Development,Technical Writing and Intellectual Property (IP) Management.',
        icon: 'icon-12',
        href: '/services-details',
        data_aos_delay:'400ms',
      },
      {
        id: 6,
        title: 'Competitive Watch',
        description: 'We will help you to keep track of your Compitative analysis and Product/Services tracking.',
        icon: 'icon-12',
        href: '/services-details',
        data_aos_delay:'400ms',
      },
    ];
    return (
        <section className="service__section p_relative">
             <div className="auto-container">
                <div className="service__data">
                    <div className="title__data">
                        <div className="sub__title">
                            <h4>Better Focus On Research</h4>
                        </div>
                        <div className="title">
                            <h2>Explore Our Services</h2>
                        </div>
                    </div>
                    <div className="row">
                        {servicesData.map((item, id)=>(
                            <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms" key={id}>
                            <div className="service__block">
                                <div className="service__icon">
                                    <i className={item.icon}></i>
                                    <div className="service__icon__two"></div>
                                </div>
                                <div className="service__text">
                                    <h4><Link to={item.href}>{item.title}</Link></h4>
                                    <p>{item.description}</p>
                                </div>
                                <div className="service__button">
                                    <Link to={item.href}><i className="icon-05"></i></Link>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
             </div>
        </section>
    );
};

export default Service;