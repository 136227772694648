import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/service-details.jpg";
import basebg from "../../assets/images/resource/base.png";


const Services = () => {
      pageTitle('UX/ UI Design');
      
      const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
      });
    
    
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);
    
        // Clear the form fields after submission if needed
        setFormData({
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: ''
        });
      };

      const servicesData = [
        { id: 1, title: 'Patent Litigation Support', slug: '/PatentLitigationSupport' },
        { id: 2, title: 'Intellectual Prop. Commercialization', slug: '/IntellectualProp.Commercialization' },
        { id: 3, title: 'Patent Prosecution Support', slug: '/PatentProsecutionSupport' },
        { id: 4, title: 'MarketResearch', slug: '/MarketResearch' },
      ];
    
      const renderServices = () => {
        return servicesData.map((service) => (
          <li key={service.id} className={service.slug === '/ui-ux-design' ? 'active' : ''}>
            <Link to={service.slug}>{service.title}</Link>
          </li>
        ));
      };
     
  return (
    
    <>
        <HeaderOne></HeaderOne>
        <BreadCrumb></BreadCrumb>
        <div className="service__details p_relative ">
            <div className="auto-container">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="service__details__left">
                            <div className="sidebar__widget">
                                <div className="all__service">
                                    <h4>All Services</h4>
                                </div>
                                <ul className="service__details__1__cat">
                                    {renderServices()}
                                </ul>
                            </div>
                            <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>                            
                                <h3>Contact Us For Any Require</h3>
                                <div className="services__icon">
                                    <i className="icon-19"></i>
                                </div>
                                <p>Need help?  Call us:</p>
                                <h4>(+800) 1234 5678 90</h4>
                            </div> 
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-12 col-md-12">
                        <div className="service__right">
                            <figure>
                                {/* <img src={service} alt="" /> */}
                            </figure>
                            <h2>Intellectual Property <span>Commercialization.</span> </h2>
                            <h4 style={{color: "#D61194"}}>Patent Commercialization</h4>
                            <p>Welcome to Matic Research, your premier destination for expert intellectual property (IP) commercialization. We are experts in using smart IP management and commercialization techniques to turn creative concepts and inventions into significant assets. Our experienced team collaborates closely with clients to identify, protect, and monetize their intellectual property portfolios. From licensing agreements to partnerships and product development, we provide tailored solutions to maximize the commercial potential of your IP assets. Trust us to drive innovation and create opportunities for growth through effective IP commercialization strategies.</p>
                            <br></br>
                            <div className='row1'>
                                    <h5 style={{color: "#D61194"}}><span>What do we do in Patent Commercialization : </span></h5>
                                    <br></br>
                                        <div className='column1'>
                                            
                                            <p>•	FTO Search</p>
                                            <p>•	Evidence of Use</p>
                                            <p>•	Inventor Watch </p>
                                            <p>•	Licensing Support</p>
                                            <p>•	Patent Portfolio Ranking</p>
                                        </div>
                                        <div className='column1'>
                                            
                                            <p>•	Patent Monitoring </p>
                                            <p>•	Infringement Search</p>
                                            <p>•	Patent Due Diligence</p>
                                            <p>•	Reverse Engineering</p>
                                        </div>
                                </div>
                                <br></br>
                                <h4>Why you should choose Matic Research for your <span style={{color: "#D61194"}}>Intellectual Property (IP) Commercialization Need</span></h4>
                                <p>Matic Research boasts a proven track record and proficiency in assisting clients with patent monetization. Our experience extends to aiding individual inventors, institutions, organizations, and private businesses in developing effective commercialization strategies. We possess the expertise to formulate business models, essential strategies, and to create successful commercialization and Intellectual Property strategies tailored for both domestic and international markets.</p>
                                <br></br>
                                <h4 style={{color: "#D61194"}}>FTO (Freedom to Operate) Search</h4>
                                <p>Our approach to conducting Freedom to Operate (FTO) searches involves a comprehensive analysis to identify alive/active patents that could hinder a company/other from producing/manufacturing or selling a specific product or implementing a particular process within a specific area or jurisdiction/terrifies. Our proficient team of researchers utilizes state-of-the-art technology and technical expertise to pinpoint potential infringement risks and provides strategic solutions to mitigate any identified risks effectively.</p>
                                <br></br><p><span style={{color: "Yellow"}}>Matic Research </span>stands out as a premier provider of intellectual property research and various analysis services. Our mission is to assist businesses across diverse industries in making informed decisions, minimizing risks, and optimizing their competitive edge. Among our key offerings is the Freedom to Operate (FTO) search, a service designed to help businesses identify and diminish potential infringement threats prior to the introduction of new products or services in the marketplace. </p>
                                <h4 style={{color: "#D61194"}}>Benefit of FTO (Freedom to Operate) search :</h4>
                                <br></br><p>•  Conducting an FTO search conserves significant time and resources, empowering you to establish a secure and well-suited business framework. </p>
                                <p>•  Ease in securing the introduction of your product to the market. </p>
                                <p>•  FTO search also enables the development team to uncover innovative ideas. </p>
                                <br></br>
                                <h4 style={{color: "#D61194"}}>Our approach to process your FTO (Freedom to Operate) Search</h4>
                                <p>•  Our team formulates diverse search strategies and techniques to pinpoint the most relevant patents to your invention that may be subject to infringement. </p>
                                <p>•  Our objective is to identify keywords that aid in the identification of an invention or product within the claims section of grant or live patents or in-process pending patent applications excluding the abounded patent applications. </p>
                                <p>•  Examining the patent involves scrutinizing individual claims, investigating entry barriers, and identifying patents and technologies that may impede the client's intellectual property patent quest. Eliminating irrelevant results and retaining a subset of patents presenting an issue is part of the process. </p>
                                <p>•  Evaluate potential issues by examining factors such as patent status, possible expiration, and/or search. Compile an invalidity opinion based on the findings. </p>
                                <p>•  Communicate the results to the client, highlighting the potential intellectual property (IP) issues and solutions, and provide recommendations for a Proactive plan. </p>
                               <br></br> <h4 style={{color: "#D61194"}}>What makes Matic Research a preferred choice for FTO (Freedom to Operate) Search</h4>
                              <br></br> <p>•	Matic Research boasts a team of proficient experts with specialized skills in essential domains.</p>
                                <p>•	A thorough exploration for prior art utilizing more than 10+ databases.</p>
                                <p>•	Possessing the ability to communicate in multiple languages, spanning nearly 120+ countries.</p>
                                <p>•	 Matic Research provides extensive regional database coverage with multiple regional language capabilities, including CN, JP, and KR, ensuring the delivery of accurate results.</p>
                                <p>•	Comprehending the legal frameworks of various nations, assessing the broadest claim scope, and evaluating the likelihood of infringement.</p>
                                <p>•	We ensure transparency in the search process by delivering interim results and maintaining continuous communication with clients.</p>
                                <p>•	Matic Research conducts structure and sequence-based searches, along with structure represented by the Markush notation.</p>
                                <p>•	Our pricing plans are tailored to meet the specific requirements of each client.</p>
                                <br></br>
                                <h4 style={{color: "#D61194"}}>Evidence of Use (EOU)</h4>
                                <p>Envision possessing the ability to confidently and precisely demonstrate the uniqueness and worth of your intellectual property. Making that vision a reality is our goal. Leveraging state-of-the-art tools and expertise, we enable companies and inventors to locate, compile, and evaluate the vital evidence required to protect and defend their intellectual property rights.</p>
                                <p>The Evidence of Use chart, which methodically aligns your claims with the good or service, is crucial at this point. The Evidence of Use acts as a guide to prove that your intellectual property rights have been violated or establish the infringement of your IP Rights.</p>
                            <br></br><h6 style={{color: "#D61194"}}>Type of Evidence of Use (EOU)</h6>
                            <p>• Patent-to-Product Mapping</p>
                            <p>• Patent-to-Standard Mapping</p>
                            <br></br><p>Matic Research provides a dependable method for creating EOU charts. Our process involves a comprehensive assessment of the relevant patent, examining its prosecution history, scope, and specification first. Subsequently, we collect and centralize data on selected products from diverse sources like teardown websites, product datasheets, and product brochures.</p>
                            <br></br><h4 style={{color: "#D61194"}}>What makes Matic Research a preferred choice for Preparing your Evidence of Use Charts (EOU)</h4>
                            
                            <p>•	Domain Experts: Crafting our EoU charts involves the insights of specialists.</p>
                            <p>•	Exactness in Claim Interpretation: Our focus is on precise claim construction in EoU charts.</p>
                            <p>•	Element Rule and Doctrine of Equivalents Integration: Our EoU charts include vital legal principles like the Element Rule and Doctrine of Equivalents to bolster your case.</p>
                            <br></br><h4 style={{color: "#D61194"}}>Inventor Watch</h4>                 
                            <p>It is essential to monitor patents in order to preserve their market value. Our field of expertise is constantly tracking inventors to prevent the registration of ideas that are the same or similar to yours. Trust Matic Research to manage your IP endeavors; our inventor watch services are comprehensive, customized, and designed to keep you ahead of the competition.</p>
                            <br></br><h6 style={{color: "#D61194"}}>What makes Matic Research a preferred choice for your inventor watch service</h6>
                            <p>• Depending on your demands, Matic Research may monitor intellectual property activities periodically, annually, or on a monthly basis. We provide a range of inventor watch services that are customized to your needs.
                            </p><p>• To keep you updated, our team carries out inventor searches, finding patents and non-patent material submitted or released by a certain inventor.
                            </p><p>
                            • The purpose of the search is to ascertain whether the inventor is participating in any illicit activity or may be disclosing confidential information to their present employer from their former employer.
                            </p>
                            <br></br><h4 style={{color: "#D61194"}}>Licensing Support</h4>
                            <p>Matic Research provides all-inclusive services that enable you to confidently negotiate the complexity of patent licensing. Drafting, negotiating, and overseeing patent licensing that is customized to your unique requirements and objectives is our team's area of expertise. Matic Research offers strategic advice and help throughout the licensing process, whether you're a patent holder hoping to make money off of your discoveries or a licensee hoping to get important technology. You can rely on us to make sure that your patent licensing contracts are solid, equitable, and designed for both parties' benefit in the marketplace.</p>
                            <br></br>
                            <h6 style={{color: "#D61194"}}>Choose Us To License Your Patent To Other </h6> 
                            <p>•	An approved method that secures royalties and turns your product idea into a reality without requiring manufacturing capital.</p>
                            <p>•	Launching the product quickly and easily requires licensing your patent to a reputable business.</p>
                            <p>•	Maintain your patent ownership.</p>
                            <p>•	Simple to penetrate international marketplaces.</p>
                            <p>•	Removing the possibility of patent infringement.</p>                          
                            <br></br>
                            <h4 style={{color: "#D61194"}}>Patent Monitoring </h4>
                            <p>In the context of intellectual property (IP), "patent monitoring" usually refers to a service that searches patent databases and periodicals for recently submitted or awarded patents that could be pertinent to a certain technology, business, or sector.</p>
                            <p>Keeping an eye on patents is essential to maintaining their market value. Our expertise at Matic Research lies in our ability to aggressively prevent the registration of inventions that are exact replicas of your concept or invention. You can rely on us to manage your IP operations since we offer comprehensive, individualized patent Monitoring services that are designed to keep you competitive.</p>
                            <br></br>
                            <h6 style={{color: "#D61194"}}>What makes Matic Research a preferred choice for your Patent Monitoring service :</h6>
                            <br></br><p>Depending on your demands, Matic Research may monitor intellectual property activities periodically, annually, or on a monthly basis. We provide a range of Patent Monitoring services that are customized to your needs.</p>
                            <p>We provide a patent monitoring service to notify you of any updates regarding the patent's current status. This contains information about your particular needs, including your legal status, current assignee, maintenance fee status, patent lifespan, security interest, lawsuit check, licensing check, and any other information as per your specific requirements.</p>
                            <br></br>
                            <h4 style={{color: "#D61194"}}>Infringement Search</h4>
                            <p>While there are many different ways to violate a patent, it usually happens when someone creates, uses, proposes to sell, or utilizes a patented invention without the patent holder's consent. </p>
                            <p>A reputable and well-respected company, Matic Research is well-known for providing insightful analysis and professional advice on intellectual property assets. Our area of expertise is helping businesses spot possible patent infringements and protects their price.</p>
                            <p>Our highly skilled search and analytics team brings a wealth of topic knowledge to the table, enabling them to perform in-depth infringement studies. This guarantees a comprehensive analysis that doesn't miss anything, resulting in accurate and targeted results. Based on thorough investigations, we produce infringement reports and offer an extensive inventory of all items that are available that violate the lawless patents or portfolio of patents.</p>
                            <br></br>
                            <h4 style={{color: "#D61194"}}>Scale and Rating of Infringement</h4>
                            <p>The Infringement Scale is the key component of our infringement evaluation approach. This unique scale, which goes from 0 to 10, measures how similar items are to the patent in question. Higher values on the scale indicate a larger possibility of infringement. The numerical number on the scale refers to the level of probable infringement.</p>
                            <p>Our dedication extends beyond mere identification. Every product in our report includes a conclusive summary, offering guidance on whether to proceed or not. Furthermore, our studies in the reports offer a strategic perspective, highlighting crucial products and their associated companies related to potential infringements. This empowers you to make informed decisions and take the necessary actions to effectively protect your valuable intellectual property assets.</p>

                            <br></br>
                            <h6 style={{color: "#D61194"}}>What makes Matic Research a preferred choice for your Patent Infringement Search</h6>
                            <p>•	Our team is experts in a comprehensive understanding of the patent. </p>
                            <p>•	A search is carried out, taking into account the patent's independent claims too.</p>
                            <p>•	Carrying out an exhaustive search on one of the chosen claims.</p>
                            <p>•	The product is analyzed by our subject-matter experts. </p>
                            <p>•	Our team offers Evidence of Use (EOU) or claim charts that link potentially infringing product qualities to key patent claim components.</p>
                            <br></br>
                            <h4 style={{color: "#D61194"}}>Patent Due Diligence</h4>
                            <p>Patent due diligence is the process of closely examining a business's portfolio of patents. It checks how much and what kind of intellectual property (IP) a corporation, business, or individual owns or has a license to use. The evaluation also covers how the company collects and safeguards intellectual property.</p>
                            <br></br>
                            <h6 style={{color: "#D61194"}}>What Advantage We Will Provide If You Choose Us</h6>
                            <p>•	The amount and caliber of intellectual property they own, enabling third parties to assign a value to them.</p>
                            <p>•	To determine which intellectual property is not being used at the moment and whose maintenance expenses are being paid needlessly.</p>
                            <p>•	To determine whether each and every IP asset is sufficiently safeguarded.</p>
                            <p>•	To make sure you have all the required permissions to use your intellectual property (IP) assets.</p>
                            <p>•	To determine if someone else is violating your intellectual property rights.</p>
                            <br></br>
                            <h4 style={{color: "#D61194"}}>Reverse Engineering </h4>
                            <p>All of us used to have this tendency of taking our toys apart to see how they were made. This interest of ours back then frequently resulted in reprimands. But now days, the same procedure can be used to find out whether a good or service uses proprietary technology. Reverse engineering is the process of dissecting technology's basic components. 
                            In reverse engineering, an object is disassembled and its components—including its code, design, and structure—are examined. Its goal is to obtain information on the parts that were used in the creation of the object. This approach can be used with software, hardware, and even genetic material from plants and animals.
                            </p>
                            <p>Our staff of reverse engineering specialists has a wealth of knowledge in a variety of analysis techniques.</p>
                            <br></br>
                            <h6 style={{color: "#D61194"}}>What makes Matic Research a preferred choice for your Reverse Engineering</h6>
                            <br></br>
                            
                            <p>•	Reverse engineering specialists of the highest caliber, possessing a wealth of knowledge in important technical fields such as software, electronics, networking, and communication.</p>
                            <p>•	As well as a wide range of consumer electronics products including wearables, tablets, smart home appliances, cameras, and so forth, we have in-depth knowledge of a wide range of devices, including phones, routers, hard drives, LCD/LED/OLED displays, RFID tags, batteries, and coating materials.</p>
                            <p>•	We provide adjustments and Customized Report according to the particular needs of the client and the chosen field of interest.</p>
                            <br></br>
                            <h4 style={{color: "#D61194"}}>Patent Portfolio Ranking  </h4>
                            <p>Your return on investment may not always be the same even if you have a lot of IP assets. What counts is the relevance of the patents based on their position in the portfolio. A comprehensive analysis and system that aids in the assessment of the different business opportunities present in the organization's business environment is the Patent Portfolio Ranking, or Patent Ranking. This aids businesses in determining the patent portfolio's advantages and disadvantages.</p>
                            <br></br>
                            <h6 style={{color: "#D61194"}}>What makes Matic Research a preferred choice for your Patent Portfolio ranking</h6>
                            <p>Our highly skilled patent portfolio ranking team, which ranks the patents, will give us a clear idea of what is coming up next. In order to identify the most valuable patents, assess monetization prospects, make well-informed judgments about prospective monetization, and support licensing plans to optimize client returns, we utilize both qualitative and quantitative criteria.</p>
                            <p>We produce a list of potential high-value patents using our unique patent-ranking system. After that, our technical experts thoroughly examine the chosen patents to determine which ones are the most valuable. In order to determine the best patents for licensing, merger, acquisition, sale, and valuation, a variety of factors are taken into account in this ranking study, including the number of family members, claim length, market application, enforceability, and validity.</p>
                            
                            
                            {/* <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="#"><i className="icon-04"></i> Patent Litigation Support</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="#"><i className="icon-04"></i> Intellectual Prop. Commercialization</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="#"><i className="icon-04"></i> Patent Prosecution Support </Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="#"><i className="icon-04"></i> MarketResearch</Link></li>
                                </div>
                            </div>
                            <p>
                                The applications of AR are vast and diverse. From entertainment and gaming, where users can see virtual characters in their surroundings, to education, where complex subjects can be visualized and explained in an immersive way,. We understand that every business has unique needs. Our technology solutions are tailored to match your specific requirements, ensuring optimal performance and efficiency. 
                            </p>
                             */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="service__contact see__pad p_relative">
            <div className="auto-container">
                <div className="service__contact__block">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="service__form__data">
                                <div className="form__title">
                                    <div className="title__data">
                                        <div className="sub__title">
                                            <h4>Contact Us</h4>
                                        </div>
                                        <div className="title">
                                            <h2>Send message</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-inner">
                                    <form onSubmit={handleSubmit} method="post" action="contact.php" className="default-form"> 
                                        <div className="row clearfix">
                                            <div className="col-xl-6 form-group">
                                                <input type="name" name="name" placeholder="Your Name *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="email" name="email" placeholder="Your Email *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="subject" placeholder="Your Subject " required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="phone" placeholder="Your Phone " required="" />
                                            </div>
                                            <div className="ccol-xl-6 col-lg-12 form-group">
                                                <textarea name="message" placeholder="Message"></textarea>
                                            </div>
                                            <div className="col-xl-12 form-group message-btn btn-box">
                                                <button className="theme-btn theme-btn-one" type="submit" name="submit-form">Send your Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 p_relative">
                            <div className="service__contact__img">
                                <figure>
                                    <img src={two} alt=" /" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterOne></FooterOne>
    </>
  );
};

export default Services;
