import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import shap1 from "../../../assets/images/banner/banner-shap-01.png";
import BannerBg from "../../../assets/images/banner/banner-bg.png";
import Banner1 from "../../../assets/images/banner/banner-01.png";
import C2 from "../../../assets/images/resource/logo.png";
import { Parallax } from 'react-parallax';


const BannerOne = () => {
  
  useEffect(() => {
    const text_2 = document.querySelector(".text_2");
    if (text_2) {
      text_2.innerHTML = text_2.innerText
        .split("")
        .map(
          (char, i) => `<span style="transform:rotate(${i * 10.3}deg)">${char}</span>`
        )
        .join("");
    }
  }, []);
  return (
    <section className="banner__one ">
      <div className="pattern-layer">
      <Parallax offset={0} speed={2.8} bgImage={shap1} strength={100} className="banner__icon pattern-1 p_absolute"></Parallax>
      </div>
      <div className="banner__data p_relative">
        <div className="banner__bg" style={{ backgroundImage: `url(${BannerBg})` }}></div>
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="banner__left">
                <div className="sub__title">
                  <h4>Better Future</h4>
                </div>
                <div className="banner__title">
                  <h1>
                    Our Research For <span>Big Success</span>{' '}
                  </h1>
                </div>
                <div className="text">
                  <p>We help companies comb through big research sets and realize otherwise hidden trends, opportunities, and threats to their business. </p>
                  <p>We believe in your Success and that matic research can help you to achieve the best result for your business, regardless of your field or target market. </p>
                </div>
                <div className="btn-box">
                  <Link to="/contact" className="theme-btn theme-btn-one">
                    <i className="icon-02"></i> Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerOne;
