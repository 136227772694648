import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/service-details.jpg";
import basebg from "../../assets/images/resource/base.png";


const DigitalMarketing = () => {
      pageTitle('Digital Marketing');
      
      const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
      });
    
    
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);
    
        // Clear the form fields after submission if needed
        setFormData({
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: ''
        });
      };

      const servicesData = [
        { id: 1, title: 'Patent Litigation Support', slug: '/PatentLitigationSupport' },
        { id: 2, title: 'Intellectual Prop. Commercialization', slug: '/IntellectualProp.Commercialization' },
        { id: 3, title: 'Patent Prosecution Support', slug: '/PatentProsecutionSupport' },
        { id: 4, title: 'MarketResearch', slug: '/MarketResearch' },
      ];
    
      const renderServices = () => {
        return servicesData.map((service) => (
          <li key={service.id} className={service.slug === '/seo-marketing' ? 'active' : ''}>
            <Link to={service.slug}>{service.title}</Link>
          </li>
        ));
      };
     
  return (
    
    <>
        <HeaderOne></HeaderOne>
        <BreadCrumb></BreadCrumb>
        <div className="service__details p_relative ">
            <div className="auto-container">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="service__details__left">
                            <div className="sidebar__widget">
                                <div className="all__service">
                                    <h4>All Services</h4>
                                </div>
                                <ul className="service__details__1__cat">
                                    {renderServices()}
                                </ul>
                            </div>
                            <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>                            
                                <h3>Contact Us For Any Require</h3>
                                <div className="services__icon">
                                    <i className="icon-19"></i>
                                </div>
                                <p>Need help?  Call us:</p>
                                <h4>(+800) 1234 5678 90</h4>
                            </div> 
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-12 col-md-12">
                        <div className="service__right">

                            <h2>Patent Prosecution <span>Support.</span> </h2>
                            <p><span style={{color: "Yellow"}}>Patent prosecution</span> is the process of a series of distinct phase encompasses initial drafting (Provisional application), final drafting (Non Provisional) filing, and working along with the patent office to grant exclusive patent protection rights to a Non obviousness invention. It also includes developing a strategy to protect your important intellectual property rights and concludes with responding to an office action of patent office.</p>
                            <br></br>
                            <p>
                            The <span style={{color: "Yellow"}}>Patent prosecution </span>starts with a distinctive innovation or idea or design. The inventors can next checks their idea to what’s currently out there by searching different databases of granted, pending, rejected and abandon patents and Non-patent literature. This phase of research allows the inventor to see deeper if a patent/Non-patent literature for a similar invention has already been filed/published. It can also help the inventor in determining what sets their product apart from available arts at that time.. 
                            </p>
                            <p><span style={{color: "Yellow"}}>To get a patent, it's essential</span> to prepare a comprehensive document containing complete detailed information, which includes drawings, descriptions, the best way to use it, and claims. Once the written document is completed, it can be submitted as an official patent application to the patent office.</p>
                            <br></br>
                            <p><span style={{color: "Yellow"}}>The initial step for a patent examiner</span> is to verify that the patent application contain all the the necessity details and parameters for patentability. The patent application includes very important things that are claims which are patentable. After that, the patent examiner will start on to prior art research and investigation, much like the inventor did at the beginning phase. The patent examiner will go over all available patents, Non-patent literature, available products and research paper, to make sure that the claimed invention isn’t similar to anything that has already been available.</p>
                            <br></br>
                            <p><span style={{color: "Yellow"}}>During the process, the patent examiner </span>and inventor may interaction with application and assessing the patent application to better understand the invention and patent application can communicate to improve their understanding of the application and speed up the prosecution process. Eventually, the patent examiner will make a decision to approve, reject, or raise objections to the patent application. They will provide reasons for their decision and offer advice on what steps to take and what can be modified. This communication is often in the form of an "office action" which prompts the inventor/applicant to provide a response to continue with the application or abandon the present application. </p>
                            <br></br>
                            <p><span style={{color: "Yellow"}}>The patent examiner will either support </span>your claims, leading to approval of your patent application, or oppose your claims, resulting in rejection of your patent application. If patent application is rejected, the next step in the process is to initiate an appeal. This means you won't obtain patent protection rights. You can also request a re-evaluation of your case, appeal the final rejection, or extend the examination period by requesting a continuing examination.</p>
                            <br></br>
                            <p><span style={{color: "Yellow"}}>At the beginning phase of the patent prosecution</span>, you have the option to submit a provisional patent application. This application can help you get more time to file a full patent application for your claimed invention. Typically, a non-provisional patent must be filed within 12 months from when you publicly disclose your claimed invention. However, by filing a provisional application, you can extend this deadline by an additional 12 months, giving you more time to prepare. But remember, if you don't file the non-provisional application within this extended time duration, you might lose the opportunity to continue the patent prosecution process of the claimed invention application.</p>
                            <br></br>
                            <p><span style={{color: "Yellow"}}>The primary goal of patent prosecution </span>is to safeguard the eligibility of your claimed invention. The patent prosecution process is initiated once a well-drafted specification and patent application have been filed. Subsequently, from the filing stage to the grant of the patent, the outcome relies significantly on the professionalism, expertise, and diligence of your experienced patent attorney.</p>
                            <br></br>
                            <p><span style={{color: "Yellow"}}>Moreover, patent prosecution also includes </span> the submission of supplementary documents, such as design blueprints and comprehensive instructions, to illustrate the uniqueness of your intellectual property. Regardless of the value of your invention, the grant of a patent is significantly influenced by the experience of your patent attorney."</p>
                            <br></br>
                            <h2>What Is The Importance Of <span> Patent Prosecution ?</span></h2>
                            <p><span style={{color: "Yellow"}}>Anyone (Inventor or Applicant)</span> thinking about filing for exclusive patent protection rights on the claimed invention should have a good understanding of the different stages involved in the patent prosecution process. The approval phase can be lengthy and comes with significant costs. Once you have a clear understanding of the requirements, you can better prepare for the process, anticipate the time it will take, and budget for the associated expenses.</p>
                            <br></br>
                            <p>In contrast to patent litigation, in which two parties are involved offensive and defensive, the patent prosecution is a collaborative and interactive stages of the phase. During patent prosecution, the applicant or their representative collaborates with the patent examiner to discuss various concerns regarding the patentability of the claimed invention.  
                            </p><br></br> <p><span style={{color: "Orange"}}> If you are looking for help/support in patent prosecution services, we Matic Research can assist you. </span>
                            </p>
                            <h2>Need help with  <span>Patent Filing ?</span></h2>
                            <br></br>
                            <p><span style={{color: "Yellow"}}>Matic Research </span>offers their clients the advantage of accessing and managing public and international patent applications worldwide. Our innovative approach ensures seamless interactions, guaranteeing that you stay informed at all times. When it comes to the filing process, it begins with the inventor drafting and reviewing the inventor/applicant, which is then submitted to the government patent office. A receipt containing the patent application number is assigned to your patent application.</p>
                            <br></br>
                            <p><span style={{color: "Yellow"}}>The initial and critical decision </span>when filing a patent pertains to the type of patent application you intend to submit. There are various types of patent applications available for submission at the patent office, including Provisional Patents application, Non-provisional Patents application, Divisional patent application, Continuation patent application, Continuation-in-part patent application, and PCT Applications. If you are in the early stages of developing of your invention, you have the option to file a provisional application, which will offer the following advantages:"</p>
                            <br></br>
                            <p> •	Secures first priority date</p>
                            <p> •	12 month window time to filed complete specification/Non-provisional patent application</p>
                            <p>  •	Low cost </p>
                            <p> •	 You have also 12 months to file PCT Application</p>
                            <p><span style={{color: "Yellow"}}>Conversely, submitting the provisional specification </span>is an optional step. If you have all the necessary details about your invention, you can skip this and move directly to filing the complete patent application/Non-provisional patent application. Another option is the PCT application, which is globally recognized and allows you to file a single application to protect your claimed invention in 152 countries. This process is governed by the Patent Cooperation Treaty (PCT). You can submit it to the National Patent Office of any country, which serves as a Receiving Office, or to the International Bureau of the World Intellectual Property Organization (WIPO).</p>
                            <br></br><br></br>
                            <h2>Why you must consider <span>Matic Research ?</span></h2>
                            <p><span style={{color: "Yellow"}}>For every inventor looking to safeguard their intellectual property rights </span>, navigating the intricacies of patent application filing can be a daunting journey. Patent filing demands a fundamental understanding and meticulous documentation to tackle the procedural challenges that confront the average individual.</p>
                            <p><span style={{color: "Yellow"}}>At Matic Research</span>, our team of experts is here to help you establish the most robust patent documentation and guide you through the intricate process of filing a patent in India and worldwide. They possess an in-depth understanding of all the procedural requirements of the patent office. Our patent professionals and advisors specialize in PCT Application and international filings. We facilitate filings in nearly all jurisdictions through our collaboration with patent attorneys in various countries.</p>
                            <br></br><br></br>
                            <h2>Want help in <span>Patent Drafting </span>? </h2>
                            <p><span style={{color: "Yellow"}}>Patent drafting </span>encompasses the creation/drafting of a patent application, encompassing the drafting of claims, drawings, and specifications. Once the patent is officially issued or granted, this draft transforms into the specification section of the document/patent application. The components of a patent application typically consist of the background, summary, detailed description, and abstract.</p>
                            <p>In essence, patent drafting focuses on the articulation of the description and claims within a patent, making it a core element of any patent application.</p>
                            <br></br>
                            <h2>Why there is a need of patent ? </h2>
                            <p>A patent provides the inventor with the right to prevent others from exploiting their invention without their consent. The proper drafting of a patent is crucial because the grant of your patent relies on how the patent application is well drafted.</p>
                            <p>Collaboration between the patent attorney and the inventor is essential. Matic Research specializes in the fundamental aspect of patent applications, which is patent drafting. </p>
                            <br></br>
                            <p>What we make sure while filing a <span style={{color: "Yellow"}}>Patent:-  </span></p>
                            <br></br>
                            <p><b>We eliminate these common errors in your patent application  mistakes:</b></p>
                            <p>     •	Failure to explain the function of the invention when it is overly obvious.</p>
                            <p>     •	Avoiding vagueness or oversimplification in your application.</p>
                            <p>     •	Focusing on specific, narrow claims for your invention rather than overly broad claims.</p>
                            <p>	    •   Eliminating any miswording or ambiguities in your patent application.</p>
                            <br></br>
                            <p><b>Consider the following factors when composing your patent:</b></p>
                            <p>•	The patent writing process demands meticulous attention, whether you're filing a provisional patent application or a complete application/Non- provisional patent application</p>
                            <p>•	Investing in professional assistance for claim drafting can prove to be a valuable investment.</p>
                            <p>•	Ensure that all documentation is completed accurately and comprehensively to avoid disqualification.</p>
                            <p>•	Ensure that claim match with the specification of the invention</p>
                            <p>•	Explain in the details of best mode of working</p>
                        <br></br>
                        <p><span style={{color: "Yellow"}}>Our team of experienced intellectual property (IP)</span> experts excels in drafting top-tier patent applications. We adhere to a rigorous methodology that incorporates multiple quality checks, efficiently navigating various stages of the patent drafting process.</p>
                        <h2>How do we prepare to <span>draft a patent application</span> ?</h2>
                        <br></br>
                        <p><span style={{color: "Yellow"}}>We prepare patent application </span>which comprises a specification that encompasses an invention's description, illustrations or drawings and most important part of the application that is claims, and the required forms for submission to the patent and trademark office.</p>
                        <p><span style={{color: "Yellow"}}>To create a comprehensive specification/detailed specification</span>, Matic Research initiates the process by providing an invention disclosure form to an inventor/applicant. This form allows the inventor/ applicant to provide sufficient information about the innovation to the patent attorney. Subsequently, we begin drafting the patent application, starting with the drafting of the claims.</p>
                        <p><span style={{color: "Yellow"}}>Once the scope of the invention </span>is accurately defined in the claims, our drafting team proceeds to create the necessary patent drawings to enhance the clarity of the invention's claims. After that the patent application is filed with the patent and trademark office to secure a patent for a new/novel/unique, and on-obvious invention, product, or method.</p>
                        <br></br>
                        <h2>  State-Of-Art-Search  </h2>
                        <p><span style={{color: "Yellow"}}>Conducting a state-of-the-art </span>search helps organizations ventures into a specific technological domain or introducing/Launching a new product, state-of-the-art search assesses the weaknesses and strengths of the technology within that particular field. This process aids in identifying/uncovering the most recent patent filings and prior art related to the technology/domain of interest. This is of keen interest to investors seeking the latest technological advancements</p>
                        <p><span style={{color: "Yellow"}}>It enhances your understanding </span>of the current state within a specific technological domain, offering profound insights into that particular field/domain. This, in turn, streamlines the decision-making process and guides your research and development efforts. It positions you for a competitive advantage by providing a broader perspective on the ongoing market developments phase. </p>
                        <br></br>
                        <h4>State-of-the-art Search is appropriate for two types of individuals: -</h4>
                        <p>	   • Those venturing into entirely new technology domains.</p>
                        <p>	   • Individuals seeking to orient their research or refine their inventions.</p>
                        <br></br>
                        <p>This <span style={{color: "Yellow"}}>comprehensive search </span>provides a summarized overview of the existing prior art, portraying the current state of situation in a specific domain. It is a time-saving tool for investors, offering insights into the evolution of a particular domain/technology and revealing previously discovered solutions. This knowledge empowers investors to make resource allocation more efficient and steer clear of unprofitable endeavors.</p>
                        <br></br>
                        <h2>How do Patentability Searches differ from <span>State-Of-Art-Searches </span>? </h2>
                        <br>
                        </br>
                        <p>A <span style={{color: "Yellow"}}>Patentability Search </span>is geared towards identifying existing prior art in the domain/technology, while a State-of-the-Art Search delves into the broader technological landscape, focusing on the development and functioning of the technology. It provides a more comprehensive view of the technology's landscape</p>
                        <p>In Short, a <span style={{color: "Yellow"}}>Patentability Search</span> is narrower in scope and focuses on the patentability of a single invention, while a State-of-the-Art Search is broader and provides a comprehensive view of the current state of a particular technological field, aiding in strategic decision-making and research planning.</p>
                        <br></br>
                        <p>Companies operating within a particular domain/technology use a State-of-the-Art search report to chart the optimal path for their research endeavors.</p>
                        <p>	   • 	Assessing the most recent advancements in the technological environment.</p>
                        <p>	   • 	Reassessing or reexamining previous Advancements/Developments/Research.</p>
                        <br></br>
                        <h4>A State-of-the-Art search enables an organization to uncover or identify important aspects such as : -</h4>
                        <p>	   • 		Identifying the current competitors in the market.</p>
                        <p>	   • 		Exploring potential partners.</p>
                        <p>	   • 		Spotting emerging trends.</p>
                        <p>	   • 		Conducting an analysis of past performance.</p>
                        <br></br>
                        <h4>Entering a new technological area…</h4>
                        <p>When a company is poised to venture into a new technological domain for investment or expansion, a State-of-the-Art search can furnish them with a comprehensive understanding of:</p>
                        <br></br>
                        <p>	   • 		Present-day Technological Innovations.</p>
                        <p>	   • 		Marketplace Competition Overview and Analysis .</p>
                        <p>	   • 		Seeking Collaboration Opportunities.</p>
                        <p>	   • 		Spotting Market Trends.</p>
                        <p>	   • 		Past Background and Contextual Information.</p>  
                        <br></br> 
                        <p>In essence, a State-of-the-Art search empowers the company with a well-rounded perspective, facilitating informed decisions for successful entry into a new technological area</p>  
                        <br></br>
                        <h2>Why you must Opt for Matic Research for <span>State-of-the-Art Analysis </span></h2>
                        <br>
                        </br>
                        <p>In essence, a <span style={{color: "Yellow"}}>State-of-the-Art search </span>empowers the company with a well-rounded perspective, facilitating informed decisions for successful entry into a new technological area</p>
                        <br></br>
                        <p>	   • 		We employ carefully crafted search queries on diverse commercial as well as open-source patent databases.</p>
                        <p>	   • 		Our researchers perform additional analysis on the results generated by these search queries.</p>
                        <p>	   • 		Subsequently, a comprehensive report is provided, aiding organizations and intellectual property, individual and research teams.</p>
                        <p>	   • 		Our State-of-the-Art search is conducted on an extensive scale to capture pertinent references within the field/domain.</p>
                       <br></br>
                       <h2><span>Patentability Search</span> </h2>
                       <br>
                       </br>
                       <p><span style={{color: "Yellow"}}>Patentability Search</span>, often referred to as a <b>novelty</b> or <b>pre-filing</b> search or <b>prior art search</b> is designed to uncover whether your invention meets the criteria of novelty and non-obviousness when compared to existing prior art, Its goal is to provide inventors with the confidence that their invention may qualify for a patent. This search is primarily focused on identifying all pertinent prior art associated with the invention. In simpler terms, a Patentability Search helps you gauge the likelihood or probability of obtaining a patent by exploring both patent literature and non-patent literature relevant to your invention. Our patentability search will conduct a comprehensive analysis of both local and global markets.</p>
                        <p><span style={{color: "Yellow"}}>We are here </span>to ensure that your product is a sound and viable business venture before committing your resources to securing a patent. Our aim is to not only save your valuable resources from unnecessary money spending but also shield you from potential legal dispu</p>






                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="service__contact see__pad p_relative">
            <div className="auto-container">
                <div className="service__contact__block">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="service__form__data">
                                <div className="form__title">
                                    <div className="title__data">
                                        <div className="sub__title">
                                            <h4>Contact Us</h4>
                                        </div>
                                        <div className="title">
                                            <h2>Send message</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-inner">
                                    <form onSubmit={handleSubmit} method="post" action="contact.php" className="default-form"> 
                                        <div className="row clearfix">
                                            <div className="col-xl-6 form-group">
                                                <input type="name" name="name" placeholder="Your Name *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="email" name="email" placeholder="Your Email *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="subject" placeholder="Your Subject " required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="phone" placeholder="Your Phone " required="" />
                                            </div>
                                            <div className="ccol-xl-6 col-lg-12 form-group">
                                                <textarea name="message" placeholder="Message"></textarea>
                                            </div>
                                            <div className="col-xl-12 form-group message-btn btn-box">
                                                <button className="theme-btn theme-btn-one" type="submit" name="submit-form">Send your Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 p_relative">
                            <div className="service__contact__img">
                                <figure>
                                    <img src={two} alt=" /" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterOne></FooterOne>
    </>
  );
};

export default DigitalMarketing;
