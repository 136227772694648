
import React from 'react';
import { Link } from 'react-router-dom';
import portfolio1 from "../../../assets/images/resource/portfolio-01.jpg";
import portfolio2 from "../../../assets/images/resource/portfolio-02.jpg";
import portfolio3 from "../../../assets/images/resource/portfolio-03.jpg";
import portfolio4 from "../../../assets/images/resource/portfolio-04.jpg";
import portfolio5 from "../../../assets/images/resource/portfolio-05.jpg";
import portfolio6 from "../../../assets/images/resource/portfolio-06.jpg";
import portfolio7 from "../../../assets/images/resource/portfolio-07.jpg";
import portfolio8 from "../../../assets/images/resource/portfolio-08.jpg";


const Projects = () => {
      const projrctData = [
        {
          id: 1,
          image: portfolio1,
          text:"Electronic & Telecommunications",
          text1:"Contact Us To More."
        },
        {
          id: 2,
          image: portfolio2,
          text:"Electrical Engineering",
          text1:"Contact Us To More."
        },
        {
          id: 3,
          image: portfolio3,
          text:"IT & Software Code",
          text1:"Contact Us To More."
        },
        {
          id: 4,
          image: portfolio4,
          text:"Chemical Engineering ",
          text1:"Contact Us To More."
        },
        {
          id: 5,
          image: portfolio5,
          text:"Biotechnology & Pharmaceuticals",
          text1:"Contact Us To More."
        },
        {
          id: 6,
          image: portfolio6,
          text:"Medical Device & Procedure ",
          text1:"Contact Us To More."
        },
        {
          id: 7,
          image: portfolio7,
          text:"Speciality in Mechanical ",
          text1:"Contact Us To More."
        },
        {
          id: 8,
          image: portfolio8,
          text:"Speciality in Automobile",
          text1:"Contact Us To More."
        },
      ];
  return (
    
    <>

        
        <div className="project__page p_relative see__pad">
        <div className="title__data">
                <div className="sub__title">
                    <h4>Industry Specialization </h4>
                </div>
                <div className="title">
                    <h2>Our Area of Experties Includes.</h2>
                </div>
                <br></br>
                
        </div>
            <div className="row">
                {projrctData.map((item, id,text)=>(
                    <div className="col-lg-3 col-md-6 colsm-12" key={id}>
                        <div className="portfolio__block p_relative">
                            <div className="portfolio__image">
                                <figure>
                                    <img src={item.image} alt="" />
                                </figure>
                            </div>
                            <div className="lower__content p_absolute">
                                <div className="protfolio__text">
                                    <div className="text__block">
                                        <h4>{item.text}</h4>
                                        <p>{item.text1}</p>
                                    </div>
                                    
                                </div>
                                {/* <div className="protfolio__button">
                                    <Link to="/projects-details" className="theme-btn theme-btn-one"> Read More<i className="icon-02"></i></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <br></br> 
            
            
        </div>


        
    </>
  );
};

export default Projects;
