import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import portfolio1 from "../../assets/images/resource/portfolio-01.jpg";
import portfolio2 from "../../assets/images/resource/portfolio-02.jpg";
import portfolio3 from "../../assets/images/resource/portfolio-03.jpg";
import portfolio4 from "../../assets/images/resource/portfolio-04.jpg";
import portfolio5 from "../../assets/images/resource/portfolio-05.jpg";
import portfolio6 from "../../assets/images/resource/portfolio-06.jpg";
import portfolio7 from "../../assets/images/resource/portfolio-07.jpg";
import portfolio8 from "../../assets/images/resource/portfolio-08.jpg";


const Projects = () => {
      pageTitle('Industry');
      const projrctData = [
        {
          id: 1,
          image: portfolio1,
          text:"Electronic & Telecommunications",
          text1:"Contact Us To More."
        },
        {
          id: 2,
          image: portfolio2,
          text:"Electrical Engineering",
          text1:"Contact Us To More."
        },
        {
          id: 3,
          image: portfolio3,
          text:"IT & Software Code",
          text1:"Contact Us To More."
        },
        {
          id: 4,
          image: portfolio4,
          text:"Chemical Engineering ",
          text1:"Contact Us To More."
        },
        {
          id: 5,
          image: portfolio5,
          text:"Biotechnology & Pharmaceuticals",
          text1:"Contact Us To More."
        },
        {
          id: 6,
          image: portfolio6,
          text:"Medical Device & Procedure ",
          text1:"Contact Us To More."
        },
        {
          id: 7,
          image: portfolio7,
          text:"Speciality in Mechanical ",
          text1:"Contact Us To More."
        },
        {
          id: 8,
          image: portfolio8,
          text:"Speciality in Automobile",
          text1:"Contact Us To More."
        },
      ];
  return (
    
    <>

        <HeaderOne></HeaderOne>
        
        <div className="project__page p_relative see__pad">
        <div className="title__data">
                <div className="sub__title">
                    <h4>Industry Specialization </h4>
                </div>
                <div className="title">
                    <h2>Major Industries.</h2>
                </div>
                <br></br>
                
        </div>
            <div className="row">
                {projrctData.map((item, id,text)=>(
                    <div className="col-lg-3 col-md-6 colsm-12" key={id}>
                        <div className="portfolio__block p_relative">
                            <div className="portfolio__image">
                                <figure>
                                    <img src={item.image} alt="" />
                                </figure>
                            </div>
                            <div className="lower__content p_absolute">
                                <div className="protfolio__text">
                                    <div className="text__block">
                                        <h4>{item.text}</h4>
                                        <p>{item.text1}</p>
                                    </div>
                                    
                                </div>
                                {/* <div className="protfolio__button">
                                    <Link to="/projects-details" className="theme-btn theme-btn-one"> Read More<i className="icon-02"></i></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <br></br> 
            <div>
                <h2 style={{color: "Yellow"}}>Our Area Of Specialization Includes</h2>
                <br></br> 
                <h4>We Are Working In Almost All Major Industries and Few Of Them Includes :-</h4>

                <br></br>
                <h3 style={{color: "#F734B6"}}> Electronic & Telecommunications</h3>
                 <p> We Cover almost all research area which includes Telecom, Robotics, Fiber Optics, Audio devices, Microprocessor, Microcontroller, Instrumentation, Signal Processing, Image Processing, Power Electronics, Electronic Circuits, Networking Devices, Consumer Electronics, Semiconductor Device, Internet of Things (IOT), Radar, Sonar, Battery Charger, Battery management, Ultra-wide band , NFC, RFIC and many more..</p>
                 <br></br>
                 <h3 style={{color: "#F734B6"}}> Electrical Engineering </h3>
                 <p> We Cover almost all research area which includes Electrical Machines, Transmission & Distributions, Solar Panel, Electric Vehicle, Transformer, Relay, Switches, AC motor, Dc Motor, Brushless DC Motor, Fans, Power Generator, house Hold Appliances, Circuit Breaker, Safety device and many more..  </p>
                 <br></br>
                 <h3 style={{color: "#F734B6"}}> IT & Software Code </h3>
                 <p> We Cover almost all research area which includes Big data, E-commerce, Web services, Online services, Search Engines, Web and Media, Firewall solution, Neural Networks, Cloud Computing, Social Networking, Payment gateway, Financial software, Virtual Reality (VR), Cloud Management,  Mobile Applications,  Artificial Intelligence, Database management,  Augmented Reality (AR), Personalization Algorithms,  Blockchain/ Cryptocurrency  and many more..</p>
                 <br></br>
                 <h3 style={{color: "#F734B6"}}> Mechanical & Automobile </h3>
                 <p> We Cover almost all research area which includes Aerospace, Mechatronic, Brake system, Fluid Mechanics, Thermal Engineering, Fleet management, automotive transportations & Power sports, Tools & Equipments, Suspension Component, Engine & Engine Components, Personal Motorized, Recreation Vehicle, Water pump, General Engineering, Door & Handle and many more...</p>
                 <br></br>
                 <h3 style={{color: "#F734B6"}}> Medical Device & Procedure  </h3>
                 <p> We Cover almost all research area which includes Ablation Technology, Oncology, Cardiovascular, Dental Device, Diagnostics, Ear, Nose & Throat, Gastrointestinal, Hypertension management Devices, Infusion Pump, Neurology, Orthopedics, Dermatology, Surgical Equipment, Urology & Incontinence , Biocompatible Polymers & coatings, minimally invasive & Endoscopic Procedure and many more..  </p>
                 <br></br>
                 <h3 style={{color: "#F734B6"}}> Biotechnology & Pharmaceuticals</h3>
                 <p> We Cover almost all research area which includes Aesthetic & Cosmetics Biotechnology, Biofuels, Vaccines, Gronomics, Biologics, Diagnostics Tool, Dosage & formulations, Stem cell & Regenerative Medicine , Synthetic Biology & Nanotechnology, Botany, hybrid plants, Cross Genetics, Biological Sequence Search, therapeutics , Tissue engineering,  Antibodies & Immunology,  Virus & Anti-virus and many more..</p>
                 <br></br>
                 <h3 style={{color: "#F734B6"}}> Chemical Engineering  </h3>
                 <p> We Cover almost all research area which includes Biochemistry, Combinational Chemistry, Food Chemistry, Fuel Cells & battery, Mining Chemicals, Organic Chemistry, Inorganics Chemistry,  Physical Chemistry, Pharmaceuticals Chemistry, Oil, Gas & Energy, Analytics Chemistry,  Polymer Chemistry, Cosmetics & personal care, Paints Engineering, Sealant,  Reaction Chamber, Furnace,  Process control, Fluid mechanics, Chemical kinetics, Catalysis, Petrochemicals and many more...</p>
                 <br></br>
              </div>
            
        </div>


        <FooterOne></FooterOne>
    </>
  );
};

export default Projects;
