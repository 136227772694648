import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import BannerBg1 from "../../../assets/images/shapes/shape-061.png";
import BannerBg from "../../../assets/images/shapes/shape-06.png";
import AboutUs from "../../../assets/images/resource/Ser.jpg";
import shape1 from "../../../assets/images/shapes/shape-01.png";
import dai from "../../../assets/scss/SVG_code.svg";
import { Background } from 'react-parallax';

const About = () => {
  return (
    <section className="about__section p_relative see__pad">
      <div className="pattern-layer">
        <div
          className="pattern-2 p_absolute"
          data-parallax='{"x": -50}'
          
        ></div>
      </div>
      <div className="boild__text">
        <h1>MaticResearch</h1>
      </div>
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6 col-md-12" >
            <div className="about__block p_relative" style={{ backgroundImage: `url(${BannerBg1})` }}>
                <img src={dai} />
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5 col-md-12">
            <div className="about__text__block">
              <div className="sub__title">
                <h4>About Company</h4>
              </div>
              <div className="title two">
                <h4>
                  Enhancing The Results With Our Proven Experience And <span>Technology.</span>
                </h4>
              </div>
              
              <div className="texts" >
                	<p style={{font:`Orange`}} ><b>Matic Research</b>,</p><p> we specialize in creating tailored solutions to safeguard your ideas, innovations, and intellectual property assets by leveraging the latest technology and legal frameworks. Our diverse client base spans globally, encompassing leading IP law firms, multinational corporations, academic entities, and emerging startups. Our comprehensive suite of intellectual property services is designed to meet the needs of businesses across various sectors, offering everything from thorough patent searches and analysis to ensuring the preservation of our clients' competitive edge through meticulous IP intelligence protection.
               </p>
              </div>
              <div className="btn-box">
                <Link to="/about" className="theme-btn theme-btn-one">
                  <i className="icon-02"></i> About more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
